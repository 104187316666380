import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish 1RM of:`}</p>
    <p>{`1-Power Clean +`}</p>
    <p>{`1-Hang Power Clean +`}</p>
    <p>{`1-Hang Squat Clean`}</p>
    <p>{`then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`Hang Power Clean (115/75)(RX+135/95)`}</p>
    <p>{`Box Jump Overs (24/20)`}</p>
    <p>{`1 of each on minute 1, 2 of each on minute 2, etc. until failure.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      